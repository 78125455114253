import * as React from "react";

import "./Button.css";

interface CommonButtonProps {
    onClick?: () => void;
    style?: React.CSSProperties;
}

const CommonButton: React.FC<CommonButtonProps> = ({ children, onClick, style }) => (
    <div className="commonButton" onClick={onClick} style={style}>{children}</div>
);

export default CommonButton;