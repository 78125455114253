import * as React from "react";

export default function ChevronLeft() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="2.8rem" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0a2d21" fill="none" strokeLinecap="round" strokeLinejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<polyline points="15 6 9 12 15 18" />
		</svg>
	);
}
